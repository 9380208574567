import { createApp } from 'vue'
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Icons from  "@element-plus/icons-vue";
//import '@/common/flexible.js';  //PC端适配解决方案  不试用移动端 
import http from '@/common/axios/http.js'  //把axios的配置项给单独分离出去 方便以后项目维护和拓展
import env from './env'
import './assets/styles/reset.css'
const app = createApp(App);
import { element } from '@/plugins/element.js'
import '@/common/flexible.js';
import dataV from '@jiaminghi/data-view'
// 全局使用图标
const icons = Icons;

for(const i in icons){
    app.component(i,icons[i]);
}

element(app)
// app.use(ElementPlus)

//判断当前系统语言环境，并设置当前语言
 store.commit("changLangState",navigator.language);

app.config.globalProperties.$httpUrl = 'https://www.baidu.com';
app.config.globalProperties.$encodeKey = env.encodeKey;
app.config.globalProperties.$axios = http;
app.config.globalProperties.$hostUrl = env.hostUrl;
app.config.globalProperties.$time = env.expireTime;
app.config.globalProperties.$authExpireTime = env.authExpireTime;
// app.config.globalProperties.$icons = icons
app.use(store).use(router).use(dataV).mount('#app')
