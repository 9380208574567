var hostUrl = '';
let expireTime = 100;
let authExpireTime = 24*7*3600;
let encodeKey = "dR0NQgK5THcQSLdq";
switch (process.env.NODE_ENV) {
    case 'development':
        //hostUrl = 'http://localhost:8088';
        // hostUrl = 'https://api.wireropeinspection.cn:8088';
        hostUrl = 'http://47.254.89.27:8088/';
        break;
    case 'production':
        hostUrl = 'http://47.254.89.27:8088/';
        break;
    default:
        break;
}

export default {
    hostUrl,
    expireTime,
    authExpireTime,
    encodeKey
}
