<template>
  <div class="home">
    <el-container style="height: auto">
      <el-header height="auto">
        <Header></Header>
      </el-header>
      <el-main style="padding-top:0">
        <div class="station-box">
          <stationList></stationList>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { onMounted, nextTick, ref ,watch} from "vue";

import Header from "@/components/header/header";

import { useRouter } from "vue-router";
import stationList from "@/components/home/stationList";
import { useStore } from "vuex";
export default {
  setup() {
    const detail = ref({});
    const router = useRouter();
    const jumpToDetail = ()=>{
        router.push(`/detail`)
    }
    const store = useStore();
    onMounted(() => {
      nextTick(() => {});
    });


    watch(
      () => store.state.auth,
      (auth) => {
          console.log(auth)
      },
      { deep: true, immediate: true }
    );
    return {
      detail,
      jumpToDetail
    };
  },
  name: "",

  data() {
    return {};
  },
  components: {
    Header,
    stationList,
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: url("../assets/back.jpg") no-repeat center center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
</style>