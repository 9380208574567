<template>
  <el-scrollbar height="88vh">
    <div class="list-box">
      <div
        class="item"
        v-for="(item, index) in stationStateArr"
        :key="index"
        @click="jumpToDetail(item.id)"
      >
        <dv-border-box-10 :color="['#2E6099', '#0DE7C2']">
          <div class="inner">
            <div class="title">
              <P
                ><span>{{ getEnglishName(item.buildingName) }}</span
                ><em>|</em><span>{{ getEnglishName(item.motorRoomName) }}</span
                ><em>|</em
                ><span>{{ getEnglistTower(item.stationName) }}</span></P
              >
            </div>
            <div class="state">
              <div class="left">
                <div class="dd">
                  <p :class="[filterSystemRunStateColor(item.syetemRunState)]">
                    {{ langObj.home.run_state }} :<span>{{
                      filterSystemRunState(item.syetemRunState, lang)
                    }}</span>
                  </p>
                  <!-- <p><img class="state-img" :src="filterRingImg" alt="" /></p> -->
                  <p>
                    <em
                      class="ring"
                      :class="[filterRingBackGround(item.safeState)]"
                    ></em>
                  </p>
                </div>
                <div class="dd">
                  <p>
                    {{ langObj.home.real_site }} :<span
                      >{{ filterFealSite(item.realSite) }}m</span
                    >
                  </p>
                  <p>
                    <img
                      :src="filterSystemRunDirection(item.runDirection, runImg)"
                      alt=""
                    />
                  </p>
                </div>
                <div class="dd">
                  <p>
                    {{ langObj.home.rope_diameter }}:<span
                      >{{ item.ropeDiameter.toFixed(1) }}mm</span
                    >
                  </p>
                </div>
                <div class="dd">
                  <p>
                    {{ langObj.home.rope_specs }}:<span>{{
                      item.ropeSpecs
                    }}</span>
                  </p>
                </div>
                <div class="dd" style="color: #bdfcc9">
                  <p>
                    {{ langObj.home.day_mileage }}:<span
                      >{{ item.dayRunRange.toFixed(1) }}km</span
                    >
                  </p>
                </div>
              </div>
              <div class="right">
                <div class="dd" style="justify-content: center">
                  <p
                    style="font-size: 0.2rem"
                    :class="[filterSystemSafeColor(item.safeState)]"
                  >
                    {{ filterSyetemSafeState(item.safeState, lang) }}
                  </p>
                </div>
                <div class="dd">
                  <p>
                    {{ langObj.home.real_speed }}:<span
                      >{{ item.realSpeed.toFixed(1) }}m/s</span
                    >
                  </p>
                </div>
                <div class="dd">
                  <p>
                    {{ langObj.home.rope_num }}:<span>{{
                      item.ropeCount
                    }}</span>
                  </p>
                </div>
                <div class="dd">
                  <p>
                    {{ langObj.home.rope_length }}:<span
                      >{{ item.MaxTestLength?.toFixed(1) }}m</span
                    >
                  </p>
                </div>
                <div class="dd">
                  <p>
                    {{ langObj.home.run_time }}:<span
                      >{{ item.dayRunTime.toFixed(1) }}h</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </dv-border-box-10>
      </div>
    </div>
  </el-scrollbar>
</template>

<script setup>
import { nextTick, onMounted, ref, reactive, onUnmounted, computed } from "vue";

// import { GetStationState } from "@/common/api/index/api.js";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import pinyin from "js-pinyin";
import {
  filterRingBackGroundHook,
  filterSystemSafeColorHook,
  httpGetStationInfoHook,
  httpGetStationStateHook,
  filterSystemRunStateHook,
  filterSystemRunStateColorHook,
  filterSyetemSafeStateHook,
  filterSystemRunDirectionHook,
} from "@/hooks/home/station.js"; //定义 ruleFrom rules
// import { GetStationState } from "@/common/api/index/api.js";

let stationInfoArr = reactive([]); //
const stationStateArr = reactive([]);
let router = useRouter();
let timer = ref(null);
const store = useStore();

const langObj = computed(() => {
  return store.state.lang.list;
});

const lang = computed(() => {
  return store.state.lang.lang;
});

const runImg = reactive({
  stop: require("../../../src/assets/icon/run_stop.png"),
  up: require("../../../src/assets/icon/run_up.png"),
  down: require("../../../src/assets/icon/run_down.png"),
});

const httpGetStationInfo = async () => {
  const { stationInfoList } = await httpGetStationInfoHook(); //使用的vue3 Hook
  stationInfoArr.length = 0;
  stationInfoArr.push(...stationInfoList);
  stationStateArr.push(...stationInfoList);
};

const httpGetStationStateList = async () => {
  const stationStateList = await httpGetStationStateHook(stationInfoArr);
  stationStateArr.length = 0;
  stationStateArr.push(...stationStateList);
};

const filterRingBackGround = filterRingBackGroundHook();
const filterSystemSafeColor = filterSystemSafeColorHook();
const filterSystemRunState = filterSystemRunStateHook;
const filterSystemRunStateColor = filterSystemRunStateColorHook;
const filterSyetemSafeState = filterSyetemSafeStateHook;
const filterSystemRunDirection = filterSystemRunDirectionHook; //获取运行方向

// const filterRingBackGround = computed(() => (state) => {
//   switch (state) {
//     case 0:
//       return "offline-bd";
//     case 1:
//       return "safe-bd";
//     case 2:
//       return "warning-bd";
//     case 3:
//       return "danger-bd";
//     case 4:
//       return "fault-bd";
//     default:
//       break;
//   }
//   return "";
// });

const jumpToDetail = (id) => {
  router.push(`/detail/${id}`);
};

const filterFealSite = (site) => {
  return site == 0 ? 0 : site.toFixed(1);
};

const getEnglishName = (name) => {
  if (lang.value == "zh-CN") {
    return name;
  } else if (lang.value == "en") {
    return pinyin.getFullChars(name);
  }
};

const getEnglistTower = (name) => {
  if (lang.value == "zh-CN") {
    return name;
  } else if (lang.value == "en") {
    name = name.replace("塔吊", "tower crane");
    name = name.replace("号", " ");
    return name;
  }
};

onMounted(() => {
  nextTick(async () => {

    await httpGetStationInfo();
    await httpGetStationStateList();
    timer.value = setInterval(() => {
      setTimeout(() => {
        if (stationInfoArr.length > 0) {
          httpGetStationStateList();
        }
      }, 0);
    }, 2000);
  });
  // console.log(pinyin.getFullChars("测试"))
});

onUnmounted(() => {
  clearInterval(timer.value);
});
</script>

<style lang="scss" scoped>
.list-box {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0 0.25rem;
  .item {
    width: 19%;
    height: 3rem;
    // background: linear-gradient(to bottom, #081c36, #08265d);
    background-color: #021e33;
    margin-bottom: 0.375rem;
    margin-right: 1.1%;
    border-radius: 5%;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    .inner {
      color: #fff;
      padding: 0.2rem 0.1rem;
      .title {
        P {
          font-size: 0.2rem;
          font-weight: 600;
          text-align: center;
          em {
            margin: 0 0.0625rem;
          }
        }
      }
      .state {
        display: flex;
        justify-content: space-between;
        padding-top: 0.25rem;
        .left {
          width: 55%;
          .dd {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 0.4rem;
            p {
              font-size: 0.175rem;
              em {
                width: 0.3125rem;
                height: 0.3125rem;
                display: block;
                border-radius: 50%;
                margin-right: 0.025rem;
              }
              span {
                margin-left: 0.0625rem;
              }
            }
            img {
              width: 0.375rem;
            }
          }
        }
        .right {
          width: 38%;

          .dd {
            margin-left: 0.0625rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 0.4rem;
            p {
              font-size: 0.175rem;
              em {
                width: 0.3125rem;
                height: 0.3125rem;
                display: block;
                border-radius: 50%;
                margin-right: 0.025rem;
              }
              span {
                margin-left: 0.0625rem;
              }
            }
            img {
              width: 0.375rem;
            }
          }
        }
      }
    }
  }
  .item:nth-of-type(5n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 1440px) {
  .list-box {
    .item {
      width: 23%;
      height: 3.75rem;
      background-color: #021e33;
      margin-right: 2%;
      .inner {
        color: #fff;
        padding: 0.2rem;
        .title {
          P {
            font-size: 0.2rem;
            font-weight: 600;
            text-align: center;
            em {
              margin: 0 5px;
            }
          }
        }
        .state {
          display: flex;
          justify-content: space-between;
          padding-top: 0.25rem;
          .left {
            width: 55%;
            .dd {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 0.5rem;
              p {
                font-size: 0.175rem;
                em {
                  width: 0.3125rem;
                  height: 0.3125rem;
                  display: block;
                  border-radius: 50%;
                  background-color: #1abc00;
                  margin-right: 0.025rem;
                }
                span {
                  margin-left: 0.0625rem;
                }
              }
              img {
                width: 0.375rem;
              }
            }
          }
          .right {
            width: 40%;
            .dd {
              margin-left: 0.0625rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 0.5rem;
              p {
                font-size: 0.1875rem;
                em {
                  width: 0.3125rem;
                  height: 0.3125rem;
                  display: block;
                  border-radius: 50%;
                  margin-right: 0.025rem;
                }
                span {
                  margin-left: 5px;
                }
              }
              img {
                width: 0.375rem;
              }
            }
          }
        }
      }
    }
    .item:nth-of-type(4n) {
      margin-right: 0;
    }
    .item:nth-of-type(5n) {
      margin-right: 2%;
    }
  }
}

@media screen and (max-width: 1366px) {
  .list-box {
    justify-content: space-between;
    .item {
      width: 30%;
      height: 3.75rem;
      background-color: #021e33;
      margin-right: 0;
      margin-bottom: 0.375rem;
      .inner {
        color: #fff;
        padding: 0.2rem;
        .title {
          P {
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            em {
              margin: 0 5px;
            }
          }
        }
        .state {
          display: flex;
          justify-content: space-between;
          padding-top: 0.25rem;
          .left {
            width: 55%;
            .dd {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 0.5rem;
              p {
                font-size: 0.1875rem;
                em {
                  width: 0.3125rem;
                  height: 0.3125rem;
                  display: block;
                  border-radius: 50%;
                  background-color: #1abc00;
                  margin-right: 0.025rem;
                }
                span {
                  margin-left: 0.0625rem;
                }
              }
              img {
                width: 0.375rem;
              }
            }
          }
          .right {
            width: 40%;
          }
        }
      }
    }
    &::after {
      content: "";
      width: 30%;
    }
    .item:nth-of-type(5n) {
      margin-right: 0;
    }
  }
}
</style>